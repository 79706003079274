import React from 'react';
import './Banner.css';
import { Animated } from 'react-animated-css';

function Banner() {
  return (
    <>
      <div className="banner__container" data-testid="banner">
        <Animated animationIn="fadeInUp">
          <h1 className="banner__container__tittle">Memote Wall</h1>
        </Animated>
        <Animated animationIn="fadeInUp" animationInDelay={200}>
          <p className="banner__container__subtitle">
            Your Free, Simple & Animated emote wall overlay :)
          </p>
        </Animated>
      </div>
      <div className="twitch"></div>
    </>
  );
}

export default Banner;
