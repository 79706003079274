import './Home.css';
import NavBar from '../components/NavBar';
import Banner from '../components/Banner';
import GenerateLinkForm from '../components/GenerateLinkForm';
import Footer from '../components/Footer';
import Emoji from '../components/Emoji';
import { useEffect, useState, React } from 'react';

function Home() {
  const [emotes, setEmotes] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      setEmotes([
        'https://static-cdn.jtvnw.net/emoticons/v2/emotesv2_e770f3ee56fa4721bcca698ecd3a144b/default/dark/2.0',
        'https://static-cdn.jtvnw.net/emoticons/v2/emotesv2_f1b9c864218c4d9cbb46f062498af2be/default/dark/2.0',
        'https://static-cdn.jtvnw.net/emoticons/v2/emotesv2_f1b9c864218c4d9cbb46f062498af2be/default/dark/2.0',
        'https://static-cdn.jtvnw.net/emoticons/v2/304486301/default/dark/2.0',
        'https://static-cdn.jtvnw.net/emoticons/v2/1547903/default/dark/2.0',
        'https://static-cdn.jtvnw.net/emoticons/v2/1290325/default/dark/2.0',
        'https://static-cdn.jtvnw.net/emoticons/v2/425618/default/dark/2.0',
        'https://static-cdn.jtvnw.net/emoticons/v2/117484/default/dark/2.0',
        'https://static-cdn.jtvnw.net/emoticons/v2/114836/default/dark/2.0',
        'https://static-cdn.jtvnw.net/emoticons/v2/64138/default/dark/2.0',
        'https://static-cdn.jtvnw.net/emoticons/v2/emotesv2_e770f3ee56fa4721bcca698ecd3a144b/default/dark/2.0',
        'https://static-cdn.jtvnw.net/emoticons/v2/emotesv2_f1b9c864218c4d9cbb46f062498af2be/default/dark/2.0',
      ]);
    }, 3000);
  }, []);

  return (
    <div className="home__container" data-testid="home">
      <NavBar />
      <Banner />
      <GenerateLinkForm />
      <Footer />
      {emotes &&
        emotes.map((emoteLink, i) => {
          return (
            <Emoji
              key={i}
              link={emoteLink}
              id={i}
            />
          );
        })}
    </div>
  );
}

export default Home;
