import React from 'react';
import './NavBar.css';
import logo from '../assets/images/jmemo162.png';
import twitchLogo from '../assets/images/tiwtch_logo.png';
import { Animated } from 'react-animated-css';

function NavBar() {
  return (
    <div className="nav-bar" data-testid="navbar">
      <div className="nav-bar__title">
        <a
          href="https://www.twitch.tv/jmemo162"
          alt="jmemo162 on twitch"
          target={'_blank'}
          rel="noreferrer"
        >
          <Animated
            animationInDelay={200}
            animationInDuration={1300}
            animationIn="bounceInLeft"
          >
            <img
              src={twitchLogo}
              className="nav-bar__title__twitch"
              alt="twitch logo"
            />
          </Animated>
          <Animated animationInDuration={1000} animationIn="bounceInRight">
            <img
              className="nav-bar__title__jmemo162"
              src={logo}
              alt="jmemo162"
            />
          </Animated>
        </a>
      </div>
    </div>
  );
}

export default NavBar;
